svg.recharts-surface {
  width: 100%;
  height: 95%;
}

svg.recharts-surface tspan {
  font-family: Manrope;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: capitalize;

  color: #002845;
}

recharts-text recharts-cartesian-axis-tick-value tspan {
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #002845;

  opacity: 0.45;
}
