.react-tabs-tab-list {
  margin: 0;
  padding: 0;
}

.react-tabs-tab {
  display: inline-block;
  position: relative;
  list-style: none;
  max-width: 300px;
  padding: 6px 12px 0;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  margin-right: 2px;
  max-width: 230px;
}

.react-tabs-tab--selected {
  display: inline-block;
  position: relative;
  list-style: none;
  max-width: 350px;
  padding: 6px 12px 0;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  margin-right: 2px;
  /*box-shadow: 2px -1px 2px 0px rgba(0, 0, 0, 0.5);*/
  background: rgba(179, 191, 199, 0.4);
  color: #ff7f33;
  z-index: 3;
  max-width: 230px;
}

.react-tabs-tab-panel {
  padding: 10px;
  display: none;
  z-index: 2;
  position: relative;
  background-color: rgba(179, 191, 199, 0.4);
  border-radius: 5px 5px 5px 5px;
}

.react-tabs-tab-panel--selected {
  padding: 10px;
  display: none;
  z-index: 2;
  position: relative;
  /*box-shadow: 2px -1px 2px 0px rgba(0, 0, 0, 0.5);*/
  display: block;
}
